.homepage-function {
    &-tabs {
        background: $white;
        box-shadow: 0px 4px 20px rgba(black, .25);
        margin-top: -45px;

        .nav-tabs {
            .nav-item {
                width: 100%;
                text-align: center;
                background: $color-theme-neon-green;
                color: $white;
                font-family: $font-theme-yantramanav;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 1.1;
                border: 0;
                border-radius: 0;

                &:last-of-type {
                    .nav-link {
                        &::after {
                            display: none;
                        }
                    }
                }
    
                .nav-link {
                    font-family: $font-theme-yantramanav;
                    color: $white;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 1.1;
                    border: 0;
                    border-radius: 0;
                    padding-top: 30px;
                    padding-bottom: 30px;
                    position: relative;
    
                    &.active,
                    &:hover,
                    &:focus {
                        color: $color-theme-neon-green;
                        background: $white;
    
                        &::after {
                            display: none;
                        }
                    }
    
                    &::after {
                        content: '';
                        width: 1px;
                        height: 60%;
                        background: rgba($white, .5);
                        position: absolute;
                        top: 50%;
                        right: 0;
                        bottom: auto;
                        left: auto;
                        transform: translateY(-50%);
                    }
                }
    
                @include media-breakpoint-up(md) {
                    width: 33.333%;
                }
            }
        }

        .tab-content {
            .tab-pane {
                font-family: $font-theme-yantramanav;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 1.3;
                color: $color-theme-green;
                min-height: 60px;
            }
        }
    }
}