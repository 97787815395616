.partial-bg {
    &-gray {
        position: relative;

        &::before {
            background: $color-theme-alt-white;
            content: '';
            top: -30px;
            left: -30px;
            right: auto;
            bottom: -30px;
            width: 100vw;
            position: absolute;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        > * {
            z-index: 2;
            position: relative;
        }
    }
}