.sac-digital {
    .homepage-hero {
        h1 {
            font-family: $font-theme-yantramanav;
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            line-height: 1.3;
            color: $color-theme-light-gray;

            @include media-breakpoint-down(md) {
                font-size: 32px;
            }
        }

        .btn {
            margin-top: 15px;

            @include media-breakpoint-up(lg) {
                display: inline-flex !important;
            }

            &.btn-primary {
                @include media-breakpoint-up(lg) {
                    max-width: 242px;
                    margin-right: 15px;
                }
            }

            &.btn-secondary {
                @include media-breakpoint-up(lg) {
                    max-width: 280px;
                }
            }
        }
    }
}