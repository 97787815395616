.contact-page-form {
    background: $white;
    box-shadow: 0 15px 30px rgba($black, .15);
    padding: 30px;

    @include media-breakpoint-up(md) {
        width: calc(100% + 200px);
        position: relative;
        z-index: 2;
    }

    .form-steps {
        position: relative;

        &::before {
            content: '';
            height: 1px;
            width: 100%;
            position: absolute;
            top: 50%;
            right: 0;
            bottom: auto;
            left: 0;
            background-color: $color-theme-light-gray;
            opacity: .25;
        }

        .btn-popover-round {
            background-color: $color-theme-black !important;
            cursor: default;
            box-shadow: 0px 4px 8px rgba($black, .15), 0 0 0 10px $white;
    
            &:hover,
            &:active,
            &:focus {
                transform: scale(.85) !important;
            }
    
            &.active {
                background-color: $color-theme-green !important;
            }
        }
    }

    .form-sections {
        input[type="email"],
        input[type="tel"],
        input[type="text"],
        select,
        textarea {
            border-radius: 0;
            border-top: 0;
            border-left: 0;
            border-right: 0;
        }

        select {
            color: #777;
        }

        .form-group {
            > label {
                padding-left: 10px;
            }
        }

        textarea {
            width: 100%;
            font-size: 16px;
            height: 100px;
            padding-left: 12px;
            padding-right: 12px;
            border-bottom-color: rgb(206, 212, 218);
        }
    }
}