.homepage-benefits {
    margin-top: 150px;
    background: $color-theme-alt-white;

    &.white-bg {
        background: $white !important;
    }

    .benefits-first-card {
        margin-top: -150px;
    }

    .statistics-card {
        .img-fluid {
            @include media-breakpoint-up(lg) {
                margin-top: -85px;
            }
        }
    }

    .chatbot-card {
        .img-fluid {
            transform: translateY(30px);

            @include media-breakpoint-up(lg) {
                margin-top: -125px;
            }
        }
    }

    .benefits-popovers {
        position: relative;
    
        .btn-popover-round {
            position: absolute !important;
            z-index: 2;
        }
    
        .popover-1 {
            top: 10%;
            right: auto;
            bottom: auto;
            left: 25%;

            @include media-breakpoint-down(md) {
                left: 20%
            }
        }
    
        .popover-2 {
            top: 50%;
            right: auto;
            bottom: auto;
            left: 50%;

            @include media-breakpoint-down(md) {
                left: 40%
            }
        }
    
        .popover-3 {
            top: 30%;
            right: auto;
            bottom: auto;
            left: 70%;

            @include media-breakpoint-down(md) {
                left: 60%
            }
        }
    }
}
