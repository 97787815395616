.video-player {
    background: $white;
    box-shadow: 0 4px 20px rgba(black, .25);
    border-radius: 0;
    width: 100%;
    height: 320px;
    position: relative;
    background: $black;

    @include media-breakpoint-down(md) {
        height: 240px;
    }

    > video {
        width: 100%;
        height: 100%;
    }

    .overlay {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($white, .85);
        backdrop-filter: blur(8px);
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 15px;

        img {
            margin-right: 15px;
        }

        h1 {
            font-family: $font-theme-yantramanav;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 1.1;
            color: $color-theme-green;
            margin: 0;
        }

        p {
            font-family: $font-theme-yantramanav;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 1.5;
            color: $color-theme-green;
            margin: 0;
        }
    }
}