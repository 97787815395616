.bordered-list {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
        padding-left: 15px;
        border-left: 4px solid $color-theme-green;
        font-family: $font-theme-yantramanav;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 1.3;
        color: $color-theme-gray;

        &:not(:last-of-type) {
            margin-bottom: 30px;
        }

        @include media-breakpoint-down(md) {
            font-size: 20px;
        }
    }
}

.unstyled-list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        font-family: $font-theme-yantramanav;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.5;
        color: $color-theme-gray;
    }
}

.border-left-green {
    border-left: 4px solid $color-theme-green;
    padding-left: 30px;

    @include media-breakpoint-down(md) {
        padding-left: 15px;
    }
}

.border-right-green {
    border-right: 4px solid $color-theme-green;
    padding-right: 30px;

    @include media-breakpoint-down(md) {
        padding-right: 15px;
    }
}