.w-extra-220 {
    @include media-breakpoint-up(md) {
        width: calc(100% + 220px);
        height: auto;
        max-width: initial;
    }
}

.z-index {
    &-2 {
        z-index: 2;
    }
}

.column-count {
    &-2 {
        column-count: 2;

        @include media-breakpoint-down(sm) {
            column-count: 1;
        }
    }
}