.section-title {
    font-family: $font-theme-yantramanav;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 1.3;
    color: $color-theme-light-gray;
    padding-left: 45px;
    border-left: 5px solid $color-theme-green;

    @include media-breakpoint-down(md) {
        font-size: 32px;
        padding-left: 15px;
        boder-left: 10px solid $color-theme-green;
    }

    small {
        display: block;
        @extend p.lead;
        color: $color-theme-light-gray;
    }

    &.no-border {
        padding-left: 0; 
        border-left: 0;
    }
}

.subsection-title {
    font-family: $font-theme-yantramanav;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 1.1;
    color: $color-theme-green;

    @include media-breakpoint-down(md) {
        font-size: 28px;
    }

    &.white {
        color: $white;
    }

    &.black {
        color: $black;
    }

    &.gray {
        color: $color-theme-light-gray;
    }

    &.font-size-sm {
        font-size: 28px;
        font-weight: 500;

        @include media-breakpoint-down(md) {
            font-size: 24px;
        }
    }
}

.block-title {
    font-family: $font-theme-yantramanav;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 1.1;
    color: $color-theme-light-gray;

    &.font-size-lg {
        font-size: 54px;

        @include media-breakpoint-down(md) {
            font-size: 36px
        }
    }
}

.page-label {
    font-family: $font-theme-yantramanav;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3;
    text-transform: uppercase;
    color: $color-theme-neon-green;
    padding-left: 15px;
    border-left: 4px solid $color-theme-neon-green;

    @include media-breakpoint-down(md) {
        font-size: 20px;
    }
}

.page-title {
    font-family: $font-theme-yantramanav;
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 1.2;
    color: $color-theme-light-gray;

    @include media-breakpoint-down(md) {
        font-size: 32px;
    }

    @include media-breakpoint-up(md) {
        width: calc(100% + 200px);
        position: relative;
        z-index: 2;
        background: $white;
        padding: 40px 30px 40px 0;
    }
}

.text-title {
    font-family: $font-theme-yantramanav;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.1;
    color: $color-theme-light-gray;
}

.top-border-title {
    padding-top: 20px;
    position: relative;
    font-family: $font-theme-yantramanav;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 1.1;
    color: $color-theme-light-gray;

    @include media-breakpoint-down(md) {
        font-size: 28px;
    }

    &::before {
        content: '';
        width: 60px;
        height: 4px;
        background: $color-theme-green;
        position: absolute;
        top: 0;
        left: 0;
    }
}