.homepage-use-cases {
    background: transparent url(../img/use-cases-bg.jpg) no-repeat center center / cover;

    @include media-breakpoint-down(md) {
        position: relative;

        &::before {
            content: '';
            background: rgba(black, .75);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &-list {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            color: $white;
            position: relative;
            padding-left: 80px;
            margin: 0 0 30px 0;
            overflow: hidden;

            .icon {
                position: absolute;
                top: 0;
                right: auto;
                bottom: auto;
                left: 0;
            }

            h3 {
                font-family: $font-theme-yantramanav;
                font-style: normal;
                font-weight: bold;
                font-size: 30px;
                line-height: 1.1;
                color: $white;
                margin: 0 0 15px 0;

                @include media-breakpoint-down(md) {
                    font-size: 24px;
                }

                &.single-line {
                    margin-top: 15px;
                }
            }

            p {
                color: $white;
                font-family: $font-theme-yantramanav;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 1.3;
            }

            .btn-text {
                color: $white;
                font-family: $font-theme-yantramanav;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 1.3;
            }
        }
    }
}