.card {
    background: $white;
    box-shadow: 0px 4px 10px rgba(black, 0.25);
    padding: 30px;
    border-radius: 3px;

    &.border-top {
        &-green-1 {
            border-top: 10px solid lighten($color-theme-green, 30%);
        }

        &-green-2 {
            border-top: 10px solid lighten($color-theme-green, 20%);
        }

        &-green-3 {
            border-top: 10px solid lighten($color-theme-green, 10%);
        }

        &-green-4 {
            border-top: 10px solid $color-theme-green;
        }
    }

    &-faq {
        .card-header {
            padding: 0;
            border: 0;
            background: transparent;

            .btn-link {
                padding-left: 0;
                padding-right: 0;
                font-family: $font-theme-yantramanav;
                color: $color-theme-black;
                font-size: 22px;
                text-decoration: none;
                outline: 0;
                box-shadow: none;
                width: 100%;
                text-align: left !important;

                @include media-breakpoint-down(md) {
                    line-height: 1.2;
                }

                &:hover,
                &:active,
                &:focus {
                    outline: 0;
                    box-shadow: none;
                }

                .fa-plus {
                    margin-right: 10px;
                    font-size: 16px;
                    margin-top: 8px;
                    transform: rotate(45deg);
                    transition: all .15s ease;
                    color: $color-theme-green;

                    @include media-breakpoint-down(md) {
                        margin-top: 4px;
                    }
                }

                &.collapsed {
                    .fa-plus {
                        margin-top: 7px;
                        transform: rotate(0deg);

                        @include media-breakpoint-down(md) {
                            margin-top: 3px;
                        }
                    }
                }
            }
        }

        .card-body {
            padding-top: 0;
            padding-left: 25px;
            padding-right: 0;
            font-size: 18px;
            color: rgba($color-theme-light-gray, .8);
        }
    }
}

.contact-info {
    .contact-card-faq {
        transform: translate3d(0, 45px, 0);
    }
}