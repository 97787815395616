@import "node_modules/bootstrap/scss/bootstrap";
@import "variables.scss";
@import "colors.scss";
@import "fonts.scss";
@import "icons.scss";
@import "mixins.scss";
@import "animation.scss";
@import "form.scss";
@import "buttons.scss";
@import "titles.scss";
@import "utilities.scss";
@import "backgrounds.scss";
@import "components/navbar.scss";
@import "components/footer.scss";
@import "components/cards.scss";
@import "components/popovers.scss";
@import "components/video-player.scss";
@import "components/brands.scss";
@import "components/tagline.scss";
@import "components/under-green-ribbon.scss";
@import "components/page-demo-footer.scss";
@import "components/ribbon-title.scss";
@import "components/bordered-lists.scss";
@import "components/tables.scss";
@import "components/simple-demo-cta.scss";
@import "homepage/hero.scss";
@import "homepage/benefits.scss";
@import "homepage/use-cases.scss";
@import "homepage/function.scss";
@import "homepage/demo-video.scss";
@import "homepage/demo-block.scss";
@import "multiusuarios/video.scss";
@import "ventas/partial-bg.scss";
@import "ecommerce/footer.scss";
@import "call-center/characteristics.scss";
@import "contact/form.scss";
@import "contact/info.scss";
