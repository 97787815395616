.brands-sponsors {
    background: $color-theme-alt-white;

    .sponsor-logo {
        mix-blend-mode: multiply;
        filter: grayscale(1);
        opacity: .8;
        transition: all .3s ease-in-out;

        &:hover,
        &:active,
        &:focus {
            filter: grayscale(0);
            opacity: 1;
        }
    }
}