.simple-demo-cta {
    position: relative;

    &.w-green-bar {
        &::before {
            content: '';
            background: $color-theme-green;
            position: absolute;
            top: auto;
            right: 0;
            bottom: 0;
            left: 0;
            height: 40px;
        }
    }

    .card {
        h4 {
            width: 75%;

            @include media-breakpoint-down(md) {
                width: 100%;
                text-align: center;
            }
        }
    }
}