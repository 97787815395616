.contact-info {
    position: relative;

    &::before {
        content: '';
        background: $color-theme-green;
        position: absolute;
        top: auto;
        right: 0;
        bottom: 0;
        left: 0;
        height: 40px;
    }

    .fab {
        font-size: 18px;
    }

    .far {
        font-size: 16px;
    }

    .fas {
        font-size: 14px;
    }

    .fab,
    .far,
    .fas {
        margin-right: 8px;
    }
}