.under-green-ribbon {
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: auto;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 140px;
        background: $color-theme-green;
        z-index: 1;
    }

    > * {
        position: relative;
        z-index: 2;
    }
}