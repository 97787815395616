.popover {
    z-index: 1;
    margin: 0 !important;
    border: 0;
    font-family: $font-theme-yantramanav;
    font-style: normal;
    font-size: 16px;
    line-height: 1.3;
    color: $color-theme-light-gray;
    background: $white;
    box-shadow: 0px 4px 10px rgba(black, .25);
    padding: 20px 25px;
    border-radius: 0;


    .popover-header {
        border: 0;
        background: transparent;
        padding: 0;
        color: black;
        margin-bottom: 5px;
        letter-spacing: 0;
        font-family: $font-theme-yantramanav;
    }

    .popover-body {
        padding: 0;
        color: $color-theme-light-gray;
        font-family: $font-theme-yantramanav;
        letter-spacing: 0;
    }

    .arrow {
        display: none;
    }

    &[x-placement^="bottom"] {
        margin-top: -10px !important;
    }

    &[x-placement^="top"] {
        margin-bottom: -10px !important;
    }

    &[x-placement^="left"] {
        margin-right: -10px !important;
    }

    &[x-placement^="right"] {
        margin-left: -10px !important;
    }
}