.sac-digital {
    .btn {
        display: inline-flex !important;

        &.btn-primary {
            background-color: $color-theme-green;
            color: $white;
            border: 0;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .15);

            &:focus,
            &:active,
            &:hover {
                background-color: darken($color-theme-green, 10%);
                border: 0;
                box-shadow: 0 5px 7px 0 rgba(0, 0, 0, .15);
            }

            &.white {
                background-color: $white;
                color: $color-theme-black;

                &:focus,
                &:active,
                &:hover {
                    background-color: darken($white, 15%);
                    color: $color-theme-black;
                }
            }
        }

        &.btn-secondary {
            background-color: $color-theme-light-green;
            color: $white;
            border: 2px solid $color-theme-green;
            box-shadow: none;
            color: $color-theme-green;

            &:focus,
            &:active,
            &:hover {
                background-color: lighten($color-theme-light-green, 10%) !important;
                border: 2px solid $color-theme-green;
                box-shadow: none;
            }
        }

        &.btn-popover-round {
            width: 40px;
            height: 40px;
            background: $color-theme-blue;
            box-shadow: 0px 4px 8px rgba(black, .15);
            border-radius: 50%;
            font-size: 20px;
            color: $white;
            font-weight: 800;
            padding: 0;
            line-height: 40px;
            text-align: center;
            display: block !important;
            transform: scale(.85);
            transition: all .25s ease-in-out;

            @include media-breakpoint-down(md) {
                transform: scale(.55);
            }

            &:hover {
                transform: scale(1);

                @include media-breakpoint-down(md) {
                    transform: scale(.65);
                }
            }
        }

        &.btn-green-text {
            font-family: $font-theme-yantramanav;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 1.1;
            color: $color-theme-neon-green;
            text-decoration: none;
        }
    }
}