.background {
    &-floating-grey {
        position: relative;

        &::before {
            content: '';
            background-color: $color-theme-alt-white;
            position: absolute;
            top: -30px;
            right: auto;
            bottom: -30px;
            left: -15%;
            width: 100vw;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        > * {
            position: relative;
            z-index: 2;
        }
    }
}