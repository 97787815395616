.ribbon-title {
    h1 {
        font-family: $font-theme-yantramanav;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 1;
        color: $white;
        position: relative;
        padding: 20px 10px 20px 0;

        @include media-breakpoint-down(md) {
            font-size: 32px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: auto;
            width: 100vw;
            height: 100%;
            background: $color-theme-neon-green;
            z-index: -1;
        }
    }
}