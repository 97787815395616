.call-center-characteristics {
    .characteristics {
        margin-top: $spacer;
        margin-bottom: $spacer;
        align-items: flex-start;

        @include media-breakpoint-up(md) {
            align-items: center;
        }

        .info {
            @include media-breakpoint-up(md) {
                text-align: center;
            }
        }

        > img {
            margin: 0 15px;
        }

        &.indented-left {
            @include media-breakpoint-up(md) {
                position: relative;
                left: 30px;
            }
        }

        &.indented-right {
            @include media-breakpoint-up(md) {
                position: relative;
                right: 30px;
            }
        }
    }
}