@font-face {
     font-family: SegoeUI;
     font-style: normal;
     font-weight: 600;
     src:
         local("Segoe UI Bold"),
         url('../fonts/segoe-ui-bold.woff2') format("woff2"),
         url('../fonts/segoe-ui-bold.woff') format("woff"),
         url('../fonts/segoe-ui-bold.ttf') format("truetype"); 
 }

/* poppins-100 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/poppins-v9-latin-100.eot'); /* IE9 Compat Modes */
    src: local('Poppins Thin'), local('Poppins-Thin'),
         url('../fonts/poppins-v9-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v9-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v9-latin-100.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v9-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v9-latin-100.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-200 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/poppins-v9-latin-200.eot'); /* IE9 Compat Modes */
    src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
         url('../fonts/poppins-v9-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v9-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v9-latin-200.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v9-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v9-latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-300 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/poppins-v9-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Poppins Light'), local('Poppins-Light'),
         url('../fonts/poppins-v9-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v9-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v9-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v9-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v9-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-regular - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/poppins-v9-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Poppins Regular'), local('Poppins-Regular'),
         url('../fonts/poppins-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v9-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-500 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/poppins-v9-latin-500.eot'); /* IE9 Compat Modes */
    src: local('Poppins Medium'), local('Poppins-Medium'),
         url('../fonts/poppins-v9-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v9-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v9-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v9-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v9-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-600 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/poppins-v9-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
         url('../fonts/poppins-v9-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v9-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v9-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v9-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v9-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-700 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/poppins-v9-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Poppins Bold'), local('Poppins-Bold'),
         url('../fonts/poppins-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v9-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v9-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-800 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/poppins-v9-latin-800.eot'); /* IE9 Compat Modes */
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
         url('../fonts/poppins-v9-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v9-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v9-latin-800.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v9-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v9-latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-900 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/poppins-v9-latin-900.eot'); /* IE9 Compat Modes */
    src: local('Poppins Black'), local('Poppins-Black'),
         url('../fonts/poppins-v9-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v9-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v9-latin-900.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v9-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v9-latin-900.svg#Poppins') format('svg'); /* Legacy iOS */
  }

@import url('https://fonts.googleapis.com/css2?family=Yantramanav:wght@300;400;500;700;900&display=swap');

p,
p.lead {
     font-family: $font-theme-yantramanav;
     font-style: normal;
     font-weight: 300;
     font-size: 20px;
     line-height: 1.4;
     color: $color-theme-gray;

     @include media-breakpoint-down(md) {
          font-size: 16px;
     }
}

.subparagraph {
     font-size: 18px;
}

.color {
     &-neon-green {
          color: $color-theme-neon-green !important;

          &:hover,
          &:active,
          &:focus {
               color: $color-theme-neon-green !important;
          }
     }
}
