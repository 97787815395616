.sac-digital {
    .navbar {
        &.bg-light {
            background-color: $white !important;
        }

        &-brand {
            background: transparent;
            box-shadow: none;
        }

        &-nav {
            .nav-link {
                font-weight: 500;
                padding-left: 15px;
                padding-right: 15px;
                opacity: 1;
                color: $color-theme-black;

                @include media-breakpoint-down(md) {
                    padding-left: 0;
                    padding-right: 0;
                }

                &:hover,
                &:active,
                &:focus {
                    color: $color-theme-green;
                }
            }

            .btn-primary {
                min-width: 100px;

                @include media-breakpoint-down(md) {
                    margin: 10px 0 0 0;
                }

                @include media-breakpoint-up(lg) {
                    margin: 0 0 0 15px;
                }
            }
        }
    }
}