.tagline {
    background: $color-theme-neon-green;
    color: $white;

    h1 {
        font-family: $font-theme-yantramanav;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 1.3;
        color: $white;
        text-align: center;

        @include media-breakpoint-down(md) {
            font-size: 24px;
        }
    }

    &.inverted {
        background: transparent;

        h1 {
            color: $color-theme-neon-green;
        }
    }

    &-cta {
        h1 {
            text-align: left;
            font-weight: 700;
        }
    }
}