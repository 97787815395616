.page-hero-demo-block {
    position: relative;
    background: $color-theme-alt-white;

    &::before {
        content: '';
        position: absolute;
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
        width: 100vw;
        background: $color-theme-green;
        height: 30px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: auto;
        bottom: auto;
        left: 0;
        width: 100vw;
        background: $white;
        height: 90px;
    }

    .partial-bg {
        position: absolute;
        top: 0;
        right: auto;
        bottom: auto;
        left: 0;
        width: 60vw;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        z-index: 2;

        @include media-breakpoint-down(sm) {
            width: 90vw;
        }
    }

    &-content {
        position: relative;
        z-index: 3;
    }

    .video-player {
        height: 520px;
    
        @include media-breakpoint-down(md) {
            height: 240px;
        }
    }
}