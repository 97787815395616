.pricing-table {
    th,
    td {
        border: 0;
    }

    thead {
        background: $color-theme-green;
        color: $white;
        font-family: $font-theme-yantramanav;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 1.2;
        border: 0;

        th {
            border: 0;

            &:nth-child(n+2) {
                text-align: center;
            }
        }
    }

    tbody {
        border: 0;

        tr {
            background: $white;
            border: 0;

            &:nth-child(2n) {
                background: darken($white, 3.5%);

                th,
                td {
                    
                    &:nth-child(2n) {
                        background: lighten($color-theme-green, 35%)
                    }
                }
            }
        }

        th,
        td {
            &:nth-child(2n) {
                background: lighten($color-theme-green, 40%)
            }

            &:nth-child(n+2) {
                text-align: center;
            }
        }

        th {
            &[scope="row"] {
                font-size: 22px;
            }
        }
    }
}