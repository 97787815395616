.footer {
    background: $color-theme-green;

    .list-label {
        font-family: $font-theme-yantramanav;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.3;
        color: $white;
    }

    ul {
        column-count: 2;
        list-style-type: none;
        padding: 0;
        margin: 10px 0 0 0;

        li {
            a {
                font-family: $font-theme-yantramanav;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 1.1;
                color: $white;
            }
        }
    }
}