.homepage-demo-block {
    position: relative;
    background: $color-theme-alt-white;

    &::before {
        content: '';
        position: absolute;
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
        width: 100vw;
        background: $color-theme-green;
        height: 100px;
    }

    .partial-bg {
        position: absolute;
        top: 0;
        right: auto;
        bottom: auto;
        left: 0;
        width: 75vw;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        z-index: 2;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .card {
        position: relative;
        z-index: 3;
    }
}